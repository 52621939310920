/* Server table header */
.rdt_TableHeader {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}

/* Server table footer */
.rdt_Pagination {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}